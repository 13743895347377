@media (max-width: 1550px) {
    .error-text-box {
        padding-top: 35px;
    }

    aside {
        width: 235px;
    }

    .right-side-cmn {
        width: calc(100% - 235px);
    }

    .table-responsive thead tr th:before {
        right: 5px;
    }

}

@media (max-width:1500px) {
    .dash-inner-boxes {
        padding: 25px 15px;
    }
    
    }
    
    @media (max-width:1400px) {
    .left-session p {
        font-size: 14px;
    }
    .dash-inner-boxes {
        padding: 20px 10px;
    }
    }